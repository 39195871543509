import { checkAllowAddress } from './text-mapping';

const nameRules = [
    v => !!v || 'โปรดกรอกชื่อลูกค้า'
];

const mobileRules = [
    v => !!v || 'โปรดกรอกเบอร์โทรศัพท์',
    v => (10 == v.length || 9 == v.length) || 'ตัวอย่างรูปแบบเบอร์โทรมือถือ. 0394992346, เบอร์บ้าน 021123411',
    v => {
        const pattern = /^\d+$/;
        const result = pattern.test(v);
        return result || 'ตัวอย่างรูปแบบเบอร์โทรมือถือ. 0394992346, เบอร์บ้าน 021123411';
    }
];

const taxRules = [
    v => !!v || 'โปรดกรอกข้อมูลบัตรประชาชนหรือเลขที่ผู้เสียภาษี',
    v => v && 13 == v.length || 'โปรดกรอกข้อมูลบัตรประชาชนหรือเลขที่ผู้เสียภาษี 13 หลัก',
    v => {
        const pattern = /^\d+$/;
        const result = pattern.test(v);
        return result || 'เฉพาะหมายเลขเท่านั้น';
    }
];

const emailRules = [
    v => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง';
    },
    v => !!v || 'โปรดกรอกอีเมล'
];

const addressRules = [
    v => !!v || 'โปรดกรอกที่อยู่',
    v => v && 250 > v.length || 'ที่อยู่ต้องไม่เกิน 250 ตัวอักษร'
];

const mapRulesV2 = (v, c) => {
    return [
        v => !!v || 'โปรดใส่ที่อยู่',
        v => ' ' != v || 'โปรดใส่ที่อยู่',
        v => checkAllowAddress(v, c) || 'อยู่นอกพื้นที่ให้บริการ'
    ];
};

const numberRules = [
    v => !!v || 'โปรดกรอกข้อมูล',
    v => /^\d+$/.test(v) || 'เฉพาะตัวเลขเท่านั้น'
];

// const numberWith2DecimalRules = [
//     v => !!v || 'โปรดกรอกข้อมูล',
//     v => /^[0-9]*\.[0-9]{2}$ or ^[0-9]*\.[0-9][0-9]$/.test(v) || 'เฉพาะตัวเลขเท่านั้น และทศนิยมเท่านั้น'
// ];

const numberWithCommasRules = [
    v => !!v || 'โปรดกรอกข้อมูล',
    v => /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/.test(v) || 'เฉพาะตัวเลขเท่านั้น'
];

const numberAndDecimalRules = [
    v => !!v || 'โปรดกรอกข้อมูล',
    v => /^\d*\.?\d{0,5}$/.test(v) || 'เฉพาะตัวเลขเท่านั้น'
];

const mapRules = [
    v => !!v || 'โปรดใส่ที่อยู่',
    v => ' ' != v || 'โปรดใส่ที่อยู่' 
];

const requiredRules = [
    v => !!v || 'โปรดกรอกข้อมูล'
];

const passwordRules = [
    v => {
        // Ref Password rule : https://stackoverflow.com/questions/3721843/how-to-allow-underscore-in-this-password-complexity-regex
        const pattern = /(?=^.{6,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=^\S*$).*$/;
        return pattern.test(v) || 'รหัสผ่านไม่ถูกต้อง';
    }
];

export {
    nameRules,
    mobileRules,
    emailRules,
    addressRules,
    numberRules,
    mapRules,
    mapRulesV2,
    requiredRules,
    passwordRules,
    numberWithCommasRules,
    numberAndDecimalRules,
    taxRules
};