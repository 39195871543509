<template>
    <div class="mt-n6" :style="{height: height + 'px'}">
        <v-row style="height: 100%">
            <v-col class="pa-0" sm="8">
                <v-img
                    class="d-sm-flex d-none"
                    :height="height + 'px'"
                    max-width=""
                    src="/images/login.jpg"
                ></v-img>
            </v-col>
            <v-col class="col-form pa-0" cols="12" sm="4">
                <div class="" style="width: 100%">
                    <label class="d-block yellow--text text--darken-3 text-h6 font-weight-bold mx-auto mb-3">
                        เข้าสู่ระบบ
                    </label>
                    <v-form class="d-block px-sm-10 px-5" v-model="isValid" ref="loginForm">
                        <v-text-field
                            solo
                            dense
                            placeholder="อีเมล์"
                            v-model="username"
                            :rules="requiredRules"
                            v-on:keyup.enter="login"
                        ></v-text-field>
                        <v-text-field
                            solo
                            dense
                            placeholder="รหัสผ่าน"
                            v-model="password"
                            :rules="requiredRules"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            v-on:keyup.enter="login"
                            @click:append="showPassword = !showPassword"
                        ></v-text-field>
                    </v-form>
                    <a @click="$router.push({ name: 'ForgotPassword'})" class="d-block text-right mt-n5 text--yellow yellow--darken-2 mr-5 mr-sm-10 text-decoration-underline" style="color: #E0E0E0">
                        ลืมรหัสผ่าน
                    </a>
                    <v-btn
                        rounded
                        color="yellow darken-3"
                        class="px-10 my-3"
                        @click="login"
                    >
                        เข้าสู่ระบบ
                    </v-btn>
                    <label class="d-block grey--text text--lighten-2 mt-1">
                        หากคุณยังไม่เป็นสมาชิก <a style="color: #F9A825" @click="$router.push({name: 'Register'})">สมัครสมาชิก</a>
                    </label>
                </div>
            </v-col>
        </v-row>
        <dialog-error 
            :topic="'เข้าสู่ระบบไม่สำเร็จ'"
            :text="errorMessage"
            ref="dialogError"
        />
    </div>
</template>

<script>
import axios from 'axios';
import { requiredRules } from '../utils/validation-rule';
import DialogError from '../components/DialogError.vue';

export default {
    data: () => ({
        height: 0,
        isValid: false,
        username: '',
        password: '',
        requiredRules,
        errorMessage: '',
        showPassword: false
    }),
    methods: {
        async login(){
            this.errorMessage = '';
            this.$refs.loginForm.validate();
            if(!this.isValid){
                this.errorMessage = 'โปรดกรอก username, password ให้ครบถ้วน';
                this.$refs.dialogError.show();
                return;
            }
            const body = {
                username: this.username.trim().toLowerCase(),
                password: this.password
            };
            let loader = this.$loading.show();
                try{
                    const response = await axios.post(process.env.VUE_APP_BASE_URL + '/login/customer', body);
                    const token = response.data.token;
                    localStorage.setItem('jwt', token);
                    localStorage.setItem('username', this.username);
                    window.location = '/';
                }catch(error){
                    this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                    this.$refs.dialogError.show();
                }finally{
                    loader.hide();
                }
        }
    },
    computed: {
    },
    components: {
        DialogError
    },
    mounted(){
        this.height = window.innerHeight - 64;
        this.$vuetify.goTo( 0, {
          duration: 0,
          offset: 0,
          easing: 'easeInOutCubic'
        });
    }
};
</script>

<style scoped>
    .col-form{
        text-align: center;
        display: flex;
        align-items: center;
    }
</style>

