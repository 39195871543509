/**
 * 
 * @param {*} channel value 1, 2, 3, other
 * @returns text value
 */
exports.channelText = (value) => {
    if ('1' == value) {
        return 'Move sameday';
    } else if ('2' == value) {
        return 'Move transit';
    } else if ('3' == value) {
        return 'Move express';
    }
    return '';
};

exports.policyText = (value) => {
    if ('1' == value) {
        return [
            '1. กรณีสั่งซื้อและชำระค่าสินค้าก่อน 13.00น. ลูกค้าจะได้รับสินค้าภายในวัน หากสั่งซื้อและชำระค่าสินค้าหลัง 13.00น. ลูกค้าจะได้รับสินค้าในวันถัดไป',
            '2. สินค้าจะเริ่มเตรียมการจัดส่ง หลังการอนุมัติคำสั่งซื้อสินค้า 2 ชั่วโมง',
            '3. ผู้รับสินค้าจะต้องแสดงบัตรประชาชน และเซ็นรับสินค้าทุกครั้งก่อนการรับสินค้า เพื่อให้บริษัทบันทึกไว้เป็นหลักฐาน โดยทางบริษัทจะไม่เปิดเผยข้อมูลดังกล่าว'
        ];
    } else if ('2' == value) {
        return [
            '1. จัดส่งสินค้า หลังได้รับการอนุมัติคำสั่ง ตามรอบการจัดส่งของบริษัท',
            '2. คุ้มครองสินค้าสูญหายจากการขนส่งสินค้าเต็มจำนวน ตามเงื่อนไขที่บริษัทกำหนด',
            '3. ผู้รับสินค้าจะต้องแสดงบัตรประชาชน และเซ็นรับสินค้าทุกครั้งก่อนการรับสินค้า เพื่อให้บริษัทบันทึกไว้เป็นหลักฐาน โดยทางบริษัทจะไม่เปิดเผยข้อมูลดังกล่าว'
        ];
    } else if ('3' == value) {
        return [
            '1. ลูกค้าจะต้องทำการบันทึกวีดิโอก่อนการแกะพัสดุ เพื่อใช้เป็นหลักฐานในการแจ้งเคลมสินค้า หากไม่มีหลักฐานทางบริษัทขอสงวนสิทธิ์ในการรับผิดชอบทุกกรณี',
            '2. หากพัสดุชำรุดเสียหาย หรือมีรอยเจาะ ฉีกขาด ให้งดรับพัสดุ และติดต่อกลับมายังบริษัทโดยทันที'
        ];
    }
    return '';
};

/**
 * using for generate select options list
 * @returns 
 */
exports.permissionOptions = () => {
    return ['1', '2', '3'].map(v => ({
        label: this.channelText(v),
        value: v,
        policyText: this.policyText(v)
    }));
};


/**
 * ------  Example ----
 *  { name: 'ทั้งหมด', value: 0 },
    { name: 'Move same day', value: 1 },
    { name: 'Move express', value: 2 },
    { name: 'Move transit', value: 3 }
 */
exports.filterChannels = () => {
    const filters = [];
    filters.push({ name: 'ทั้งหมด', value: 0 });
    [1, 2, 3].forEach(v => {
        filters.push({
            name: this.channelText(v), value: v
        });
    });
    return filters;
};

const monthThai = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.'
];

/**
 * 
 * @param {Date} date 
 */
exports.thaiDate = (date) => {
    if (!date) {
        return '';
    }

    return `${date.getDate()} ${monthThai[date.getMonth()]} ${Number(date.getFullYear()) + 543}`;
};

/**
 * 
 * @param {Date} date 
 * @returns 
 */
exports.thaiDateTime = (date) => {
    if (!date) {
        return '';
    }

    return `${date.getDate()} ${monthThai[date.getMonth()]} ${Number(date.getFullYear()) + 543} ${date.getHours()}:${date.getMinutes()} น.`;
};

/**
 *  ** Base on web customer
 * @param {*} job 
 * @returns 
 */
exports.jobStatusText = (job) => {
    if (!job || !job.ordering) {
        return 'สถานะผิดพลาด';
    }
    const value = job.ordering.status;
    if ('before_new' == value) {
        return 'รอยืนยันรายการ';
    }
    if ('new' == value) {
        return 'รายการใหม่';
    }
    if ('wait_confirm' == value || 'wait_confirm_with_approval' == value) {
        return 'รออนุมัติ';
    } else if ('wait_messenger' == value) {
        return 'ที่ต้องจัดส่ง';
    } else if ('sending' == value) {
        return 'กำลังจัดส่ง';
    } else if ('sent' == value) {
        return 'ส่งมอบแล้ว';
    } else if ('cancel' == value) {
        return 'ยกเลิก';
    }
};

exports.jobStatusColorText = (job) => {
    if (!job || !job.ordering) {
        return { icon: 'red', text: 'red--text text--darken-3' };
    }
    const value = job.ordering.status;
    if ('before_new' == value) {
        return { icon: 'white', text: 'white--text text--darken-1' };
    }
    if ('new' == value) {
        return { icon: 'yellow darken-3', text: 'yellow--text text--darken-3' };
    }
    if ('wait_confirm' == value || 'wait_confirm_with_approval' == value) {
        return { icon: 'yellow darken-3', text: 'yellow--text text--darken-3' };
    } else if ('wait_messenger' == value) {
        return { icon: 'yellow darken-3', text: 'yellow--text text--darken-3' };
    } else if ('sending' == value) {
        return { icon: 'yellow darken-3', text: 'yellow--text text--darken-3' };
    } else if ('sent' == value) {
        return { icon: 'yellow darken-3', text: 'yellow--text text--darken-3' };
    } else if ('cancel' == value) {
        return { icon: 'grey darken-3', text: 'grey--text text--darken-3' };
    }
};

/**
 * Change to thai name *** Base on web admin
 * @param {String} value 
 */
exports.getStatusThaiName = (value) => {
    if ('new' == value || 'before_new' == value) {
        return 'มาใหม่';
    } else if ('wait_confirm' == value || 'wait_confirm_with_approval' == value) {
        return 'รออนุมัติ';
    } else if ('wait_messenger' == value) {
        return 'ที่ต้องจัดส่ง';
    } else if ('sending' == value) {
        return 'กำลังส่ง';
    } else if ('sent' == value) {
        return 'จัดส่งสำเร็จ';
    } else if ('cancel' == value) {
        return 'ยกเลิก';
    }
};

exports.productTypeToText = (type) => {
    const listProductType = [
        { name: 'เงินสด', value: '1' },
        { name: 'ทองคำแท่ง', value: '2' },
        { name: 'ทองรูปพรรณ', value: '3' },
        { name: 'อัญมณีและเครื่องประดับ', value: '4' },
        { name: 'เอกสาร / พัสดุ', value: '5' },
        { name: 'อื่นๆ', value: '6' }
    ];
    const result = listProductType.find(l => l.value == type);
    return result ? result.name : '';
};

exports.allowDistrictNontaburee = [
    'เมืองนนทบุรี',
    'บางกรวย',
    'บางใหญ่',
    'บางบัวทอง',
    'ปากเกร็ด'
];

exports.allowPathumtanee = [
    'ตำบลรังสิต',
    'อำเภอลำลูกกา',
    'ตำบลคลองหนึ่ง',
    'ตำบลคลองสอง',
    'ตำบลคลองสาม',
    'ตำบลคลองสี่'
];

exports.allowZoneAddress = [
    'คันนายาว',
    'คลองเตย',
    'คลองสาน',
    'คลองสามวา',
    'จตุจักร',
    'จอมทอง',
    'ดอนเมือง',
    'ดุสิต',
    'ดินแดง',
    'ตลิ่งชัน',
    'ทวีวัฒนา',
    'ทุ่งครุ',
    'ธนบุรี',
    'บางกอกน้อย',
    'บางกอกใหญ่',
    'บางกะปิ',
    'บางเขน',
    'บางขุนเทียน',
    'บางคอแหลม',
    'บางแค',
    'บางซื่อ',
    'บางนา',
    'บางบอน',
    'บางพลัด',
    'บางรัก',
    'บึงกุ่ม',
    'ป้อมปราบศัตรูพ่าย',
    'ประเวศ',
    'ปทุมวัน',
    'พระนคร',
    'พระโขนง',
    'พญาไท',
    'ภาษีเจริญ',
    'มีนบุรี',
    'ยานนาวา',
    'ราชเทวี',
    'ราษฎร์บูรณะ',
    'ลาดกระบัง',
    'ลาดพร้าว',
    'วังทองหลาง',
    'วัฒนา',
    'สวนหลวง',
    'สะพานสูง',
    'สาทร',
    'สายไหม',
    'สัมพันธวงศ์',
    'หนองแขม',
    'ห้วยขวาง',
    'หลักสี่'
];

exports.checkAllowAddress = (value, channel) => {

    if (!value || channel != 1) {
        return true;
    }

    let result = false;

    if (value.includes('จังหวัดนนทบุรี')) { // allow นนทบุรี
        for (let v of this.allowDistrictNontaburee) {
            result = value.includes(`อำเภอ${v}`);
            if (result) {
                break;
            }
        }
    } else if (value.includes('จังหวัดปทุมธานี')) { // allow ปทุมธานี
        for (let v of this.allowPathumtanee) {
            result = value.includes(v);
            if (result) {
                break;
            }
        }
    } else {  // allow เขต
        for (let v of this.allowZoneAddress) {
            result = value.includes(`เขต${v}`);
            if (result) {
                break;
            }
        }
    }
    return result;
};